// App routes
export const AppRoutes = {
  'home': '/',
  'info': '/info',
  'bbva-btge-users-application-solution': '/bbva-btge-users-application-solution',
  'bbva-btge-error-solution': '/bbva-btge-error-solution',
  'bbva-btge-terms-and-conditions-solution': '/bbva-btge-terms-and-conditions-solution',
  'bbva-btge-consents-management-solution': '/bbva-btge-consents-management-solution',
  'bbva-btge-plans-management-solution': '/bbva-btge-plans-management-solution',
  'bbva-btge-assignments-users-capabilities-solution': '/bbva-btge-assignments-users-capabilities-solution',
  'bbva-btge-api-management-solution': '/bbva-btge-api-management-solution',
  'bbva-btge-statistics-solution': '/bbva-btge-statistics-solution',
  'bbva-btge-organizations-management-solution': '/bbva-btge-organizations-management-solution',
  'bbva-btge-notifications-organization-solution': '/bbva-btge-notifications-organization-solution'
};

// MFE routes
export const MFERoutes = {
  'bbva-btge-users-application-solution-applications': '/organizations/:organizationId',
  'bbva-btge-users-application-solution-applications-h2h': '/organizations/:organizationId/h2h',
  'bbva-btge-users-application-solution-application-detail': '/organizations/:organizationId/detail/:id',
  'bbva-btge-users-application-solution-application-detail-h2h': '/organizations/:organizationId/detail-h2h/:id',
  'bbva-btge-users-application-solution-application-new': '/organizations/:organizationId/new',
  'bbva-btge-users-application-solution-application-new-h2h': '/organizations/:organizationId/new-h2h',
  'bbva-btge-users-application-solution-api-accounts-modal': '/organizations/:organizationId/api-account-modal',
  'bbva-btge-users-application-solution-application-update': '/organizations/:organizationId/update/:id',
  'bbva-btge-users-application-solution-application-update-h2h': '/organizations/:organizationId/update-h2h/:id',
  'bbva-btge-users-application-solution-application-activation': '/organizations/:organizationId/activation/:id',
  'bbva-btge-error-solution-generic-service': '/',
  'bbva-btge-terms-and-conditions-solution-list': '/terms-and-conditions/:resourceType',
  'bbva-btge-terms-and-conditions-solution-update': '/terms-and-conditions/:resourceType/update',
  'bbva-btge-terms-and-conditions-solution-view-pdf': '/terms-and-conditions-view',
  'bbva-btge-consents-management-solution': '/management/terms',
  'bbva-btge-consents-management-solution-detail': '/management/terms/details',
  'bbva-btge-plans-management-solution-account-plans-list': '/account-plans',
  'bbva-btge-plans-management-solution-account-plans-create': '/account-plans/create',
  'bbva-btge-plans-management-solution-account-plans-update': '/account-plans/update/:accountPlanId',
  'bbva-btge-plans-management-solution-api-plans-list': '/api-plans',
  'bbva-btge-plans-management-solution-api-plans-create': '/api-plans/create',
  'bbva-btge-plans-management-solution-api-plans-update': '/api-plans/update/:apiPlanId',
  'bbva-btge-plans-management-solution-api-plans-detail': '/api-plans/detail/:apiPlanId',
  'bbva-btge-assignments-users-capabilities-solution-users-new': '/organizations/:organizationId/new',
  'bbva-btge-assignments-users-capabilities-solution-users-list': '/organizations/:organizationId',
  'bbva-btge-api-management-solution-list': '/',
  'bbva-btge-api-management-solution-detail': '/detail/:id',
  'bbva-btge-api-management-solution-update': '/update/:apiId',
  'bbva-btge-statistics-solution-home': '/statistics',
  'bbva-btge-statistics-solution-ranking-apps-apis': '/statistics/ranking',
  'bbva-btge-statistics-solution-apis-services': '/statistics/apis-services/:statsType',
  'bbva-btge-statistics-solution-apps': '/statistics/applications',
  'bbva-btge-organizations-management-solution-list': '/',
  'bbva-btge-organizations-management-solution-create': '/organizations/new',
  'bbva-btge-organizations-management-solution-detail': '/organizations/:organizationId',
  'bbva-btge-organizations-management-solution-go-live': '/organizations/go-live/:organizationId',
  'bbva-btge-organizations-management-solution-update': '/organizations/edit/:organizationId',
  'bbva-btge-organizations-management-solution-edit-apis': '/organizations/edit-apis/:organizationId',
  'bbva-btge-organizations-management-solution-apis-detail': '/organizations/:organizationId/apis/:apiId',
  'bbva-btge-organizations-management-solution-add-channel': '/organizations/:organizationId/channels/:channel/add',
  'bbva-btge-notifications-organization-solution-list': '/organizations/:organizationId/notifications',
  'bbva-btge-notifications-organization-solution-detail': '/organizations/:organizationId/notifications/:notificationId',
};

// Hide sidebar paths
export const pathsToHideSidebar = [
  'bbva-btge-users-application-solution-application-update',
  'bbva-btge-users-application-solution-application-update-h2h',
  'bbva-btge-users-application-solution-application-activation',
  'bbva-btge-users-application-solution-application-new',
  'bbva-btge-users-application-solution-application-new-h2h',
  'bbva-btge-api-management-solution-update',
  'bbva-btge-terms-and-conditions-solution-update',
  'bbva-btge-plans-management-solution-account-plans-create',
  'bbva-btge-plans-management-solution-account-plans-update',
  'bbva-btge-plans-management-solution-api-plans-create',
  'bbva-btge-plans-management-solution-api-plans-update',
  'bbva-btge-assignments-users-capabilities-solution-users-new',
  'bbva-btge-organizations-management-solution-create',
  'bbva-btge-organizations-management-solution-go-live',
  'bbva-btge-organizations-management-solution-update',
  'bbva-btge-organizations-management-solution-edit-apis',
  'bbva-btge-organizations-management-solution-add-channel'
];

// Route links
export const routeLinks = {
  parents: {
    'bbva-btge-organizations-management-solution-detail': [
      'bbva-btge-users-application-solution-applications',
      'bbva-btge-users-application-solution-applications-h2h',
      'bbva-btge-users-application-solution-application-detail',
      'bbva-btge-users-application-solution-application-detail-h2h',
      'bbva-btge-assignments-users-capabilities-solution-users-list',
      'bbva-btge-terms-and-conditions-solution-list',
      'bbva-btge-notifications-organization-solution-list',
      'bbva-btge-notifications-organization-solution-detail'
    ],
    'bbva-btge-users-application-solution-applications-h2h': [
      'bbva-btge-users-application-solution-application-detail-h2h'
    ]
  },
  exceptions: {
    'bbva-btge-users-application-solution-applications-h2h': [
      'bbva-btge-users-application-solution-applications'
    ],
    'bbva-btge-users-application-solution-application-detail-h2h': [
      'bbva-btge-users-application-solution-applications'
    ]
  },
  ignoreParents: {
    'bbva-btge-terms-and-conditions-solution-list': [{
      resourceType: 'portal'
    }, {
      resourceType: 'connect'
    }]
  },
  fullWidthPages: [
    ...pathsToHideSidebar,
    'bbva-btge-users-application-solution-api-accounts-modal',
    'bbva-btge-terms-and-conditions-solution-view-pdf'
  ]
};
